@import '~antd/dist/antd.css';


.ant-menu-horizontal {
    border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item-selected {
    border-bottom: 2px solid white;
}

.slick-dots > li > button {
    background: black;
}

.homepage-menu {
    /*font-weight: bold;*/
    /*font-size: 16px;*/
}