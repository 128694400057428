.paper-item-row:hover {
    cursor: pointer;
    border: 1px solid #d9d9d9;
    box-shadow: 0 0 5px 1px #cccccc;
    border-radius: 8px;
}

.paper-item-row {
    border-top: 1px solid rgba(255, 255, 255, 0.0);
    border-left: 1px solid rgba(255, 255, 255, 0.0);
    border-right: 1px solid rgba(255, 255, 255, 0.0);
    border-bottom: 1px solid #d9d9d9;
    margin-left: 0 !important;
    margin-right: 12px !important;
}

.img-box {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 4px
}

.filter-panel-divider {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}

.citation-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 8px;
    font-size: 12px;
    cursor: pointer;
}

.citation-box:active {
    background-color: white;
}